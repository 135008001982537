<template>
  <div
    class="
      w-full
      h-36
      bg-black
      mx-auto
      fixed
      bottom-0
      flex
      flex-col
      sm:flex-row
      justify-center
      items-center
      gap-x-5
    "
    style="z-index: 1"
  >
    <div>
      <img src="@/assets/logo.png" alt="logo-footer" class="w-48" />
    </div>
  </div>
</template>
